<template>
  <d-table
      :items="bookings"
      :is-busy="isBusy"
      :totalRows="totalRows"
      :per-page="itemsPerPage"
      :tablefields="tableFields"
      @current-page:update="onCurrentPageUpdate"
  >
  </d-table>
</template>

<script>
  import Booking from "@/classes/doinsport/Booking";
  import {YEAR_DASH_MONTH_DASH_DAY} from "@/utils/date";
  import {getClubBookingsByClientId} from "@api/doinsport/services/bookings/booking.api";

  export default {
    data: () => ({
      isBusy: true,
      commingSoon: true,
      canceled: false,
      bookings: [],
      itemsPerPage: 10,
      date: null,
      operator: null,
      currentPage: 1,
      totalRows: 1,
    }),
    created () {
      this.loadComingSoonBookings();
    },
    methods: {
      onCurrentPageUpdate (page) {
        this.currentPage = page;
        this.loadComingSoonBookings();
      },
      loadComingSoonBookings () {
        this.isBusy = true;
        this.date = this.$moment.utc().format(YEAR_DASH_MONTH_DASH_DAY);
        this.operator = 'before';
        this.canceled = false;

        getClubBookingsByClientId(this.$route.params.id, this.itemsPerPage, this.currentPage, '', this.canceled, this.date, this.operator)
            .then( (response) => {
              this.bookings = [];
              this.totalRows = response.data['hydra:totalItems'];

              for (let item of response.data['hydra:member']) {
                this.bookings.push(new Booking(item));
              }
            })
            .finally(() => {
              this.isBusy = false;
            })
        ;
      },
    },
    computed: {
      tableFields () {
        return [
          { key: "name", label: this.$t("views.bookings.index.bookingName"), sortable: true },
          { key: "startAt", label: this.$t("views.bookings.index.reservationDate"), sortable: true},
          { key: "bookingPlaygrounds", label: this.$t("views.bookings.index.playground"), sortable: true },
          { key: "activity", label: this.$t("views.bookings.index.activity"), sortable: true},
          { key: "duration", label: this.$t("views.bookings.index.duration"), sortable: true},
        ];
      }
    },
  }
</script>
